
import Accordion from "../components/Accordion";
import SourceTexts from "../components/SourceTexts";

const CategoryTitle = (props) => {
	return (
		<span>
			{props.title}&nbsp;
			{/* <span className="text-gray-300"> Questions </span> */}
			(<span className="text-orange-300 font-bold"> {props.count} </span>)
		</span>
	);
}

const dummyQuestions = (
	<div className="text-left p-4 font-sans space-y-4">
		<div className="p-4 shadow-md bg-slate-100">
			<b>1.</b>What is the most common type of cancer in males? <br/>
				a. Lung cancer <br/>
				b. Prostate cancer <br/>
				c. Colorectal cancer <br/>
				d. Cervical cancer <br/>
				Answer: b. Prostate cancer <br/>
				<div className="text-right">
					<button className="btn"> 
						<i className="fa fa-plus-circle" aria-hidden="true"></i> 
					</button>
				</div>
		</div>
		<div className="p-4 shadow-md bg-slate-100">
			<b>2.</b>What is the most common type of cancer in males? <br/>
				a. Lung cancer <br/>
				b. Prostate cancer <br/>
				c. Colorectal cancer <br/>
				d. Cervical cancer <br/>
				Answer: b. Prostate cancer <br/>
				<div className="text-right">
					<button className="btn"> 
						<i className="fa fa-plus-circle" aria-hidden="true"></i> 
					</button>
				</div>
		</div>
	</div>
);

export default function TestLayout() {	

	return (
		<div className="flex-1">
			<h2 className="text-2xl font-bold text-center m-4">This is a test</h2>
			<div className="flex h-full w-full">
				<SourceTexts />
				<div className="flex-1 text-center bg-slate-200">
					<h3 className="text-xl text-black mt-4">Generated Questions (15)</h3>
					<div className="mt-4">
						<Accordion>
							<div className="font-sans" key={1} title={<CategoryTitle title="True or False" count="5"/>}>
								{dummyQuestions}
							</div>
							<div className="font-sans" key={2} title={<CategoryTitle title="Comprehension" count="4"/>}>
								{dummyQuestions}
							</div>
							<div className="font-sans" key={2} title={<CategoryTitle title="Multiple Choice" count="4"/>}>
								{dummyQuestions}
							</div>
							<div className="font-sans" key={2} title={<CategoryTitle title="Fill the Blanks" count="10"/>}>
								{dummyQuestions}
							</div>
						</Accordion>
					</div>
				</div>
				<div className="flex-1 text-center bg-slate-300">
					<h3 className="text-xl text-black mt-4">Test Questions</h3>
					<div className="mt-4">
						{dummyQuestions}
					</div>
				</div>
			</div>
		</div>
	);

}

