import React from "react";

export default function QuestionListItem(props) {
  return (
    <div className="">
      <div className="">
        <h3 className="text-xl font-semibold">{props.title}</h3>
        <p>{props.answer}</p>
        {props.extra && (
          <ul className="">
            {props.extra}
            <li>True/False</li>
            <li>Multiple Choices</li>
            <li>Questions & Answers</li>
            <li>Fill-Ups</li>
          </ul>
        )}
      </div>
    </div>
  );
}
