import React, { useState, useEffect, useContext, useCallback } from "react";
import { SocketContext } from "../services/socket";
import cn from "classnames";

import { useFirestoreQueryData, useFirestoreCollectionMutation, useFirestoreDocumentMutation,  useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";
import {
	query,
	doc,
	where,
	collection,
} from "firebase/firestore";
import { firestore } from "../firebase";
import GeneralQuestion from "./GeneralQuestion";
import Loader from "./Loader";
import addAnimation from '../assets/animations/add.json';
import Lottie from "react-lottie-player";

// const CategoryTitle = (props) => {
// 	return (
// 		<span>
// 			{props.title}&nbsp;
// 			{/* <span className="text-gray-300"> Questions </span> */}
// 			(<span className="text-orange-300 font-bold"> {props.count} </span>)
// 		</span>
// 	);
// }

export function Question({ uid, testId, question, number, isInTest }) {
	const questionsCol = collection(firestore, "users", uid, "questions");
	const questionDoc = doc(questionsCol, question.id)
	const editQuestion = useFirestoreDocumentMutation(questionDoc, {
		merge: true,
	})
	const deleteQuestion = useFirestoreDocumentDeletion(questionDoc);

	const addToTest = () => {
		if (question.testIds.includes(testId)) return

		editQuestion.mutate({
			testIds: [...question.testIds, testId]
		})
	}

	const removeFromTest = () => {
		if (!question.testIds.includes(testId)) return

		editQuestion.mutate({
			testIds: question.testIds.filter(id => id !== testId)
		})
	}

	const handleDeleteQuestion = () => {
		const ok = window.confirm("Are you sure you want to delete this question?")
		if (ok) {
			deleteQuestion.mutate()
		}
	}

	console.log("question testIds", question.testIds, question.testIds.includes(testId))

	const handleReject = () => {
		if (isInTest) {
			removeFromTest()
		}
		else {
			handleDeleteQuestion()
		}
	}

	return (
		<div className="p-4 shadow-md bg-slate-100 text-left test__content-center">
			<div className="flex">
				<div className="px-1 font-semibold">{(number + 1) + "."}</div>
				<GeneralQuestion action={question.type + "Action"} question={question} />
				<div className="px-1 font-semibold flex flex-col">
					<button className="text-red-500" onClick={handleReject}>x</button>
					<div className="grow flex flex-col justify-end">
					</div>
				</div>
			</div>
			<div className="text-right text-gray-400">
				{TYPE_TO_LABEL[question.type]} &nbsp;
				{!isInTest &&
					<>
						{question.testIds && !question.testIds.includes(testId) &&
							<button disabled={editQuestion.isLoading} onClick={addToTest}><i className="fa fa-arrow-right text-teal-700" aria-hidden="true"></i></button>
						}
						{question.testIds && question.testIds.includes(testId) &&
							<svg xmlns="http://www.w3.org/2000/svg" className={cn("w-4 h-4 rounded-full bg-teal-700 text-white inline-block relative -top-1")} viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
							</svg>
						}
					</>
				}

			</div>
		</div>
	)

}

function QuestionList({ uid, sourceId, testId, onSelect }) {
	const questionsRef = collection(firestore, "users", uid, "questions");
	const questionsQuery = query(questionsRef, where("sourceId", "==", sourceId));
	const questions = useFirestoreQueryData(["questions", { sourceId }], questionsQuery, { idField: 'id', subscribe: true });

	return (
		<>
			{questions.isLoading &&
				<div className="text-lg text-center"> Loading questions... </div>
			}

			{questions.isError &&
				<div className="text-lg text-center"> Error... </div>
			}
			{!questions.data && <h3>No questions generated</h3>}
			{questions.data?.length > 0 &&
				<>
					<div className="mt-4">{questions.data.length}</div>
					<div className="mt-4 space-y-4 mx-4 font-sans">
						{questions.data && questions.data.map((question, index) => (
							<Question key={question.id} uid={uid} testId={testId} question={question} number={index} />
						))}
					</div>
				</>
			}
		</>

	)
}


/* function FunctionChoice(props) {
	return (
		<div className="flex w-1/2 items-center space-x-2 px-3 cursor-pointer" onClick={props.onSelect}>
			<svg xmlns="http://www.w3.org/2000/svg" className={cn("w-4 h-4 rounded-full", { "text-gray-400 bg-white": !props.isChecked }, { "text-white bg-orange-500": props.isChecked })} viewBox="0 0 20 20" fill="currentColor">
				<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
			</svg>
			<div className="text-lg text-black">
				{props.label}
			</div>
		</div>
	)

} */

/* const submitRequest = (socket, textInput, actions) => {
	console.log("actions", actions);
	socket.emit("get analysis", { text: textInput, actions: actions });
}; */

/* const CHOICES = [
	"Multiple Choice",
	"Comprehension",
	"Fill the Blanks",
	"True or False"
] */

/* const ACTION_MAP = {
	"Multiple Choice": "multipleChoice",
	"Comprehension": "advancedQuestions",
	"Fill the Blanks": "fillTheBlanks",
	"True or False": "trueFalse"
} */

const TYPE_MAP = {
	"multipleChoiceAction": "multipleChoice",
	"advancedQuestionsAction": "advancedQuestions",
	"fillTheBlanksAction": "fillTheBlanks",
	"trueFalseAction": "trueFalse"
}

const TYPE_TO_LABEL = {
	"multipleChoice": "Multiple Choice",
	"advancedQuestions": "Comprehension",
	"fillTheBlanks": "Fill the Blanks",
	"trueFalse": "True or False"
}

// const SAMPLE_MC = { "action": "multipleChoiceAction", "results": [{ "question": "Who took Helen from her husband Menelaus?", "answers": ["Paris of Troy", "Odysseus", "Menelaus", "Zeus"], "correct": 0 }, { "question": "Where did the Trojan War take place?", "answers": ["Athens", "Sparta", "Egypt", "Troy"], "correct": 3 }, { "question": "What is the core of the Iliad about?", "answers": ["The journey home of Odysseus", "The siege of Troy", "The death of Achilles", "Four days and two nights in the tenth year of the decadelong siege of Troy"], "correct": 3 }, { "question": "What is one of the most important works about the Trojan War?", "answers": ["The Odyssey", "The Iliad", "Homer's Odyssey", "Homer's Iliad"], "correct": 3 }] }

export default function Questions({ uid, source, testId, onSelect }) {
	const questionsRef = collection(firestore, "users", uid, "questions");
	/* const questionsQuery = query(questionsRef, where("sourceId", "==", sourceId)); */
	/* const questions = useFirestoreQueryData(["questions", { sourceId }], questionsQuery, { idField: 'id', subscribe: true }); */
	const addQuestion = useFirestoreCollectionMutation(questionsRef);
	const [page, setPage] = useState('output');

	/* const sourceTextsCol = collection(firestore, "users", uid, "sourceTexts"); */
	/* const selectedSourceDoc = sourceId? doc(sourceTextsCol, sourceId): null
	const selectedSource = useFirestoreDocumentData(["sourceTexts", sourceId], selectedSourceDoc, { idField: 'id', subscribe: true }, {enabled: !!sourceId}); */

	/* console.log("selectedSource", selectedSource) */

	/* const newQuestionsBatch = writeBatch(firestore);
	const sendQuestionsBatch = useFirestoreWriteBatch(newQuestionsBatch, {
		onSuccess() {
			console.log("New questions added!");
			setIsLoadingResults(false)
		},
		onError(error) {
			console.log("New questions failed!", error.message);
			setIsLoadingResults(false)
		},
		onMutate() {
			console.log("Adding questions...");
		},
	}); */

	const socket = useContext(SocketContext);

	/* const [selectedChoice, setSelectedChoice] = useState(CHOICES[0]) */
	const [isLoadingResults, setIsLoadingResults] = useState(false)

	const onGenerateResults = useCallback((data) => {
		(async () => {
			console.log(data);
			const preparedQuestions = data.results.map((question, index) => {
				return {
					...question,
					type: TYPE_MAP[data.action],
					sourceId: source.id,
					testIds: []
				}
			})
	
			for (let i = 0; i < preparedQuestions.length; i++) {
				await addQuestion.mutate(preparedQuestions[i])
			}
			setIsLoadingResults(false)
	
			//sendQuestionsBatch.mutate()
		})()
	}, [source, addQuestion])
	

	useEffect(() => {
		if(!source) return
		const lastResults = JSON.parse(localStorage.getItem('last-results'))
		console.log(lastResults);
		if (lastResults) {
			onGenerateResults(lastResults)
			localStorage.removeItem('last-results')
		}
	}, [source, onGenerateResults])

	useEffect(() => {
		socket.on("got analysis", onGenerateResults);
		return () => {
			socket.off("got analysis");
		};
	}, [socket, onGenerateResults])

	return (
		<div className="flex-1">
			<div className="tabs">
				<div className={`tabs__item tab__50 ${page === 'source' && 'tabs__item-active'}`} onClick={() => setPage('source')}>
					<span className="material-icons font__25">
						source
					</span>
					&nbsp;Source Text
				</div>
				<div className={`tabs__item tab__50 ${page === 'output' && 'tabs__item-active'}`} onClick={() => setPage('output')}>
					<span className="material-icons font__25">
						output
					</span>
					&nbsp;Output
				</div>
			</div>
			{(!isLoadingResults && !source) &&
				<div className="text-center">
					<Lottie
						loop
						animationData={addAnimation}
						play
						style={{ width: 300, height: 300, margin: 'auto' }}
					/>
					<h3 className="text-3xl text-bold">Pick a questionnaire from the left or create a new one</h3>
				</div>
			}
			{page === 'source' && 
				<div className="mleft--15 mtop--15">
					<h4>{source.title}</h4>
					<div>
						{source.text}
					</div>
				</div>
			}
			{page === 'output' &&
				<div className="text-center">
					{isLoadingResults &&
						<Loader />
					}
					{source &&
						<QuestionList key={source.id} uid={uid} sourceId={source.id} testId={testId} onSelect={onSelect} />
					}
				</div>
			}
		</div>
	);
}

