export default function QTrueOrFalse({ data, index }) {
	const question = data.question
	const answer = data.answer

	return (
		<div>
			<div className="font-semibold"> 
				<span className="text-gray-400 font-normal">{index}. </span> {question} 
			</div>
			<div>
				<span className="text-gray-400">Answer: </span> { answer.toString() }
			</div>
		</div>
	)
}