import { useRef, useEffect, useState, useContext } from "react";
import { SocketContext } from "../services/socket";
import youtubeIcon from "../assets/img/youtube.png";

function YoutubeTranscript({ onLoad }) {
	const socket = useContext(SocketContext);
	const [isLoading, setIsLoading] = useState(false)
	const inputRef = useRef()
	const [loadError, setLoadError] = useState("")
	const [youtubeUrl, setYoutubeUrl] = useState()

	const submitRequest = () => {
		setIsLoading(true)
		socket.emit("get youtube transcript", { youtubeUrl: inputRef.current.value});
	};

	useEffect(() => {
		socket.on("got youtube transcript", (data) => {
			setIsLoading(false)
			if(data.error){
				setLoadError(data.error)
				setTimeout(() => setLoadError(""), 2000)
			}
			else{
				onLoad(data.transcript)
			}
		});

		return () => {
			socket.off("got youtube transcript");
		};
	}, [socket, onLoad])

	const handleClick = async (e) => {
		e.preventDefault();
		submitRequest()
	}
	return (
		<>
			<div className="text-left">
				<div className="bg-white pl-3 inline-block">
					<img src={youtubeIcon} width={32} alt="youtube icon" style={{display:"inline-block"}} /> &nbsp;
					<input type="text" ref={inputRef} value={youtubeUrl} onChange={ (e) => setYoutubeUrl(e.target.value) }  id="youtube-url-input" className="p-2" placeholder="Paste YouTube URL" name="youtube-url" />
				</div>
				{youtubeUrl &&
					<button className="btn btn__inverted" onClick={handleClick} style={{paddingTop: "6px", paddingBottom: "6px"}}> 
						Load Youtube text 
					</button>
				}
				{loadError &&
					<span className="text-red-600 font-normal"> {loadError} </span>
				}
				{isLoading &&
					<span className="lds-ring"></span>
				}
			</div>
		</>
	)
}

export default YoutubeTranscript