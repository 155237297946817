import { useState, useEffect, useContext } from "react"
import '../assets/scss/demo.scss';
import { SocketContext } from "../services/socket";
import Loader from "./Loader";
/* import { getAuth } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import {collection} from "firebase/firestore";
import { firestore } from "../firebase"; */
import CreateActions from "./CreateActions";
import CreateInput from "./CreateInput";
import Results from "./Result";

function Create() {
	//const logsCol = collection(firestore, "logs");
	/* const addLogEntry = useFirestoreCollectionMutation(logsCol); */
	//const auth = getAuth();
	//const user = useAuthUser(["user"], auth);
	/* const userId = useRef(user.data?.uid) */

	const socket = useContext(SocketContext);
	const [value, setValue] = useState('');
	const [wordCount, setWordCount] = useState(0);
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [results, setResults] = useState();
	const [actionModal, setActionModal] = useState(false);
	const [resultsModal, setResultsModal] = useState(false);

	const handleGenerate = (type, outputLang, selectedOption) => {
		setError(null);
		if (!value){
			setError('Please fill in the source text to continue.');
			return;
		}
		let option = selectedOption;
		let actions;
		if(type === 'Bullet Points'){
			actions = { 'summary': true, 'size': 'Bullet Points' }
		}
		else if(type === 'summary'){
			actions = { 'summary': true, 'size': option }
		}
		else{
			actions = { [option]: true }
		}
		
		if(!option) {
			setError('Please select a type');
			return;
		}
		actions["outputLang"] = typeof outputLang === 'string' ? outputLang : 'same';
		console.log(value);
		localStorage.setItem("last-source", value)
		submitRequest(socket, actions)
	}
	const submitRequest = (socket, actions) => {
		setLoading(true);
		console.log("actions", actions);
		socket.emit("get analysis", { text: value, actions: actions });
	};
	useEffect(() => {
		socket.on("got analysis", (data) => {
			setActionModal(null);
			setResults(data);
			console.log(data);
			setLoading(false);
			localStorage.setItem("last-results", JSON.stringify(data))
			setResultsModal(true);
			
			/* let logEntry = {
				text: value,
				userId: userId.current
			}
			if (data.error) logEntry.error = data.error
			if (data.stats) logEntry = {
				...logEntry,
				...data.stats,
				choice: selectedOption
			}
			addLogEntry.mutate(logEntry) */
		});

		return () => {
			socket.off("got analysis");
		};
	}, [socket,  value])
	return (
		<div className="demo">
			<div className="demo__left">
				{!results &&
					<div className="card demo__left-inner">
						{!loading &&
							<>
								<h2 className="font__40">
									Quickly create<br /><b className="text__primary">multiple choice questions</b>, <b className="text__primary">summaries</b>, <b className="text__primary">bullet points</b> <br/>and many other types from any text.
								</h2>
								<CreateInput value={value} setValue={setValue} wordCount={wordCount} onWordCountChange={ (count) => setWordCount(count) }/>
								<div onClick={() => setActionModal(true)} className="btn btn__primary btn__xl btn__continue" disabled={!value}>Continue</div>
							</>
						}
						{loading && <Loader text={"Generating... "} />}
					</div>
				}
				{results &&
					<>
						<div className="demo__left-resultsDesk">
							<Results results={results} setResults={setResults} onBack={ () => {} } onClear={ () => setValue('') } />
						</div>
						<div className='demo__left-resultsMob flex flex__col'>
							<button className="btn btn__primary" onClick={() => setResultsModal(true)}>View Result</button>
							<button className="btn btn__inverted" onClick={() => setResults(null)}>Create new</button>
						</div>
					</>
				}
			</div>
			<div className="demo__right-desk">
				<CreateActions loading={loading} submitAction={handleGenerate} error={error} wordCount={wordCount} />
			</div>
			{ resultsModal &&
				<div className="modal__overlay active demo__left-resultsMob">
					<div className="modal active animate__animated animate__fadeIn">
						<div className="modal__header">
							<div className="modal__header-title">
								<h2>Select your options</h2>
							</div>
						</div>
						<div className="modal__content">
							<div className="modal__body">
								<Results results={results} setResults={setResults} onBack={ () => setActionModal(true)} onClear={()=> {setActionModal(null); setResultsModal(null); setValue('');}}/>
							</div>
						</div>
					</div>
				</div>
			}
			{ actionModal &&
				<div className="modal__overlay active">
					<div className="modal active animate__animated animate__fadeIn">
						<div className="modal__header">
							<div className="modal__header-title">
								<h2>Select your options</h2>
							</div>
							<div className="close-modal modal__x">
								<svg viewBox="0 0 20 20" onClick={() => { setActionModal(null); setResultsModal(null); }}>
									<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
								</svg>
							</div>
						</div>
						<div className="modal__content">
							<div className="modal__body">
								<CreateActions loading={loading} submitAction={handleGenerate} error={error} />
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default Create