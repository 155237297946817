import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from "react-router-dom";
import ReactModal from "react-modal";
import { useState } from "react";
import HomePage from "./pages/HomePage";
import TestLayout from "./pages/TestLayout";
import AuthPage from "./pages/AuthPage";
import './assets/scss/main.scss';
import './assets/scss/nav.scss';

import { getAuth } from "firebase/auth";
import { useAuthUser, useAuthSignOut } from "@react-query-firebase/auth";


import logoImage from "./assets/img/logo.png";
import TestsPage from "./pages/TestsPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import FaqPage from "./pages/FaqPage/FaqPage";
import CookieBanner from "./components/CookieBanner";

function Header() {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const auth = getAuth();
	const user = useAuthUser(["user"], auth);
	const signOutMut = useAuthSignOut(auth);
	const navigate = useNavigate();
	const location = useLocation();
	const logout = () => {
		signOutMut.mutate();
		navigate('/');
	}
	const goTo = (page) => {
		const targetDiv = document.getElementById(page);
		targetDiv.scrollIntoView({ behavior: 'smooth' });
	}
	return (
		<header className="nav">
			<Link to="/" style={{position: 'relative'}}>
				<div className="flex flex-row justify-center logo">
					<img src={logoImage} alt="Logo" style={{ filter: 'hue-rotate(213deg)', width: '70px' }} />
					<span className="text__bold font__30 mtop--5 text__normal"> Smarty<b className="text__primary">Q</b></span>
				</div>
				<div className="text__normal font__14" style={{ position: 'absolute', right: '-55px', bottom: '-2px' }}>Your powerful AI assistant</div>
			</Link>
			<div className="flex-1 flex justify-end">
				<nav>
					{!user.data &&
						<div className="flex flex__row nav__desk">
							{location.pathname === '/' && 
								<div>
									<div className="btn btn__transparent" onClick={() => goTo('why')}>
										What is SmartyQ
									</div>
									{/* <div className="btn btn__transparent" onClick={() => goTo('pricing')}>
										Pricing
									</div> */}
									<div className="separator mtop--10"></div>
									<div className="btn btn__primary-outline btn__rounded" onClick={() => navigate('/auth')}>
										<span className="material-icons">
											account_circle
										</span>
										Login/Register
									</div>
								</div>
							}
						</div>
					}
					{user.data &&
						<div className="flex flex__row nav__desk">
							{location.pathname === '/' &&
								<>
									<div className="btn btn__transparent" onClick={() => goTo('why')}>
										What is SmartyQ
									</div>
									{/* <div className="btn btn__transparent" onClick={() => goTo('pricing')}>
										Pricing
									</div> */}
									<div className="btn btn__transparent" onClick={() => navigate('/tests')}>
										My Library
									</div> 
									<div className="separator mtop--10"></div>
								</>
							}
							<div className="account__name">
								<span className="material-icons">
									account_circle
								</span>
								<span className="uppercase">{user.data.displayName}</span>
								<div className="text__danger mleft--30 pointer" onClick={logout}>Logout</div>
							</div>
						</div>
					}
					{/* <div className="nav__mob" onClick={() => setMenuIsOpen(true)}>
						<img alt={'menu'} src="https://api.iconify.design/simple-line-icons:menu.svg"  width="40" />
					</div> */}
				</nav>
			</div>
			<div className="nav__mob">
				<MenuModal isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)} />
			</div>
		</header>
	)
}
function MenuModal({ isOpen, onClose}) {
	const goTo = (page) => {
		const targetDiv = document.getElementById(page);
		targetDiv.scrollIntoView({ behavior: 'smooth' });
		onClose();

	}
	return (
		<ReactModal
			isOpen={isOpen}
			ariaHideApp={false}
			style={
				{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.45)"
					},
					content: {
						position: 'relative',
						margin: '0 auto',
						marginTop: '20%',
						width: '60%',
					}
				}
			}
		>
			<div className="text-black">
				<div className="p-5 pt-0 text-center">
					<div className="mt-10">
						<div className="btn btn__transparent" onClick={() => goTo('why')}>
							What is SmartyQ
						</div>
					</div>
				</div>
				<div className="text-right space-x-2">
					<button className="btn" onClick={onClose}> Close </button>
				</div>
			</div>
		</ReactModal>
	)
}

function Footer() {
	const location = useLocation();
	return (
		<footer>
			{location.pathname !== '/tests' && 
				<>
					<ul className="flex text-xl m-2 mr-10 space-x-8 justify-center items-center mtop--30">
						<li> <Link to="/terms"> TERMS </Link> </li>
						<li> <Link to="/privacy"> PRIVACY</Link> </li>
						<li> <Link to="/faq"> FAQ </Link> </li>
					</ul>
					<hr />
					<div className="m-10 text-center">
						Copyright © {new Date().getFullYear()} <span className="font-bold text-lg">Smarty<b className="text__primary">Q</b></span>
					</div>
				</>
			}
		</footer>
	)
}

function App() {
	return (
		<Router>
			<div id="app" className="flex flex-col h-full">
				<Header />
				<main className="flex grow items-stretch">
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/test" element={<TestLayout />} />
						<Route path="/auth" element={<AuthPage />} />
						<Route path="/tests" element={<TestsPage />} />
						<Route path="/terms" element={<TermsPage />} />
						<Route path="/privacy" element={<PrivacyPage />} />
						<Route path="/faq" element={<FaqPage />} />
					</Routes>
				</main>
				<Footer />
				<CookieBanner />
			</div>
		</Router>
	)
}

export default App;
