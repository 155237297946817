import React from 'react';
import ReactDOM from 'react-dom/client';

import {
	QueryClient,
	QueryClientProvider,
} from 'react-query'
import './index.css';
import App from './App';
import { socket, SocketContext } from './services/socket';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<SocketContext.Provider value={socket}>
				<App />
			</SocketContext.Provider>
		</QueryClientProvider>
	</React.StrictMode>
);

