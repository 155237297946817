import io from "socket.io-client";
import React from "react";

const path = window.location.origin + "/";

const SOCKET_IO_NAMESPACE = path;
const SOCKET_IO_PATH = "/socket.io/";
const realSocket = io(SOCKET_IO_NAMESPACE, {
	path: SOCKET_IO_PATH,
});

export const socket = realSocket

export const SocketContext = React.createContext();
