import React, { useState, useEffect, useRef, useContext } from "react";
import ReactModal from 'react-modal';
import cn from "classnames";
import { useFirestoreQueryData, useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { SocketContext } from "../services/socket";
import {
	query,
	collection,
} from "firebase/firestore";
import { firestore } from "../firebase";
import CreateActions from "./CreateActions";
import CreateInput from "./CreateInput";

function AddSourceTextModal({ value, setValue, loading, onSubmit, onCancel, isOpen, error }) {
	const [page, setPage] = useState(1);

	return (
		<ReactModal
			isOpen={isOpen}
			ariaHideApp={false}
			style={
				{
					overlay: {},
					content: {
						position: 'relative',
						margin: '70px auto',
						width: '60%',
						maxHeight: '85%'
					}
				}
			}
		>
			<div className="flex flex__row">
				{page === 2 &&
					<div className="btn" onClick={() => setPage(1)}>
						<span className="material-icons font__30">
							arrow_back_ios
						</span>
						<b className="font__20">Back</b>
					</div>
				}
				<div className="btn float--right" onClick={onCancel}>
					<span className=" material-icons font__40">
						clear
					</span>
				</div>
			</div>
			{page === 1 && 
				<div className="demo demo__inner">
					<div className="demo__left">
						<h3>Enter your source text</h3>
						<CreateInput value={value} setValue={setValue} />
						<div className="btn btn__primary btn__lg" disabled={!value} onClick={() => setPage(2)}>
							Continue
						</div>
					</div>
				</div>
			}
			{page === 2 &&
				<div className="demo demo__inner">
					<CreateActions loading={loading} submitAction={onSubmit} error={error} />
				</div>
			}
		</ReactModal>
	)

}

export default function SourceTexts({ uid, onSelect, isMobile }) {
	const socket = useContext(SocketContext);
	const sourceTextsRef = collection(firestore, "users", uid, "sourceTexts");
	const sourceTextsQuery = query(sourceTextsRef);
	const sourceTexts = useFirestoreQueryData(["sourceTexts"], sourceTextsQuery, { idField: 'id', subscribe: true });
	const addSourceText = useFirestoreCollectionMutation(sourceTextsRef);
	const [selected, setSelected] = useState();
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState('');
	const [error, setError] = useState(null);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);

	const handleGenerate = (type, outputLang, selectedOption) => {
		setError(null);
		if (!value) {
			setError('Please fill in the source text to continue.');
			return;
		}
		let option = selectedOption;
		let actions;
		if (type === 'Bullet Points') {
			actions = { 'summary': true, 'Bullet Points': true }
		} else {
			actions = { [option]: true }
		}
		if (type === 'summary') {
			actions['summary'] = true;
		}
		if (!option) {
			setError('Please select a type');
			return;
		}
		actions["outputLang"] = typeof outputLang === 'string' ? outputLang : 'same';

		localStorage.setItem("last-source", value)
		submitRequest(socket, actions)
	}
	const submitRequest = (socket, actions) => {
		setLoading(true);
		socket.emit("get analysis", { text: value, actions: actions });
		setIsAddModalOpen(false);
		setLoading(false);
	};
	useEffect(() => {
		const lastSource = localStorage.getItem('last-source')
		if(lastSource){
			addSourceText.mutate({ title: "Demo Source", text: lastSource })
			localStorage.removeItem('last-source')
		}
	})

	const isFirstTime = useRef(true)
	useEffect(() => {
		if(sourceTexts.data?.length > 0 && isFirstTime.current){
			isFirstTime.current = false
			setSelected(sourceTexts.data[0])
			onSelect(sourceTexts.data[0])
		}
	}, [sourceTexts.data, onSelect])

	return (
		<div className={`test__content-left ${isMobile ? 'test__content-leftMobile' : 'test__content-leftDesktop'}`}>
			<div className="test__content-leftDeskInner">
				<button className="btn btn__primary dashboard__add" style={{width: '100%'}} onClick={() => setIsAddModalOpen(true)}>
					<span className="material-icons vm-align">add</span>
					<span>Add Source Text</span>
				</button>
				{sourceTexts.isLoading &&
					<div className="text-lg text-center"> Loading source texts... </div>
				}
				{sourceTexts.data?.length === 0 &&
					<div className="text-lg text-center"> Add a source text to start generating questions </div>
				}
				{sourceTexts.data?.length > 0 &&
					<ul className="space-y-4">
						{sourceTexts.data && sourceTexts.data.map((st, index) => (
							<li 
								onClick={ () => { setSelected(st); onSelect(st)} } key={st.id} 
								className={cn("source-text m-2 p-4 pt-0 font-sans cursor-pointer text-left bg-slate-100 overflow-hidden text-ellipsis dashboard__menu-item", { "dashboard__menu-itemActive": st.id === selected?.id })}
							>
								<div className="dashboard__menu-itemNumber">{index + 1}</div>
								<div className="font-semibold mt-2"> {st.title}</div>
							</li>
						))}
					</ul>
				}
			</div>
			<AddSourceTextModal value={value} setValue={setValue} loading={loading} isOpen={isAddModalOpen} onCancel={() => setIsAddModalOpen(false)} onSubmit={handleGenerate} error={error} />
		</div>
	);

}

