import React from "react";
import QuestionListItem from "./QuestionListItem";
import questions from "./questions";
export default function QuestionList() {
  return (
    <div className="space-y-4">
      {questions.map((item, index) => (
        <QuestionListItem key={index} extra={item.extra} title={item.title} answer={item.answer} />
      ))}
    </div>
  );
}
