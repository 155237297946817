import { useEffect, useState } from "react";
import "../assets/scss/CookieBanner.scss";
import { checkConsent, submitCookieChoices } from "../firebase";

const ESSENTIAL_COOKIES = [
	{
		service: "Firebase",
		type: "first-party",
		domain: ".smartyq.eu",
		purpose: "Cookies essential for the SmartyQ website to operate and provide security (remember consent choice, autorization etc).",
		country: "Greece",
		expires: "1 year for consent cookie, otherwise duration same as session",
		policy: "https://policies.google.com/privacy"
	},
]

const ANALYTICS_COOKIES = [
	{
		service: "Firebase Analytics",
		type: "first-party",
		domain: ".smartyq.eu",
		purpose: "This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports of Firebase Analytics.",
		country: "Greece",
		expires: "1 year 1 month",
		policy: "https://policies.google.com/privacy"
	},
	{
		service: "Youtube",
		type: "third-party",
		domain: ".youtube.com",
		purpose: "This set of cookies is set by YouTube to track views of embedded videos and for advertising reasons.",
		country: "United States",
		expires: "Persistent",
		policy: "https://www.youtube.com/howyoutubeworks/user-settings/privacy/"
	},
]

function Cookie({service, type, domain, purpose, country, expires, policy}) {

	return (
		<div className="cookie__description">
			<div> <u>Service</u>: <b> {service} </b> ({type}) </div>
			<div> <u>Domain</u>: {domain} </div>
			<div> <u>Purpose</u>: {purpose} </div>
			<div> <u>Country</u>: {country} </div>
			<div> <u><span>Expires&nbsp;In</span> </u>: {expires} </div>
			<div> <u>Policy&nbsp;Link</u>: <a href={policy} target="_blank" rel="noreferrer" style={{wordBreak: "break-word"}}> {policy} </a></div>
		</div>
	)
}

function CookieBanner({ isStatic, background, textColor, campaign_id }) {

	const [isModal, setIsModal] = useState(false)
	const [hasConsent, setHasConsent] = useState(false)

	useEffect(() => {
		const isCookie = checkConsent()
		if(isCookie) setHasConsent(true);
		else setHasConsent(false);


	}, [])

	const acceptAll = () => {
		submitCookieChoices(["a"]); 
		setHasConsent(true);
		setIsModal(false);
	}

	const acceptSelected = () => {
		submitCookieChoices([]); 
		setHasConsent(true);
		setIsModal(false);
	}
	

	return (
		<>
			{!isModal && !hasConsent &&
				<div className="cookie__banner">
					<div className="cookie__wrapper">
						<h4>SmartyQ uses Cookies</h4>
						<div>
							SmartyQ uses cookies to improve your experience on this site and personalize it based on your preferences.
							You can click on "Accept All" to proceed with all the cookies, or select the desired cookie categories
							and click on "Accept Selected"
						</div>
						<div className="cookie__options">
							<span><input type="checkbox" checked disabled /> Essential</span>
							<span><input type="checkbox" value="a" data-bind="checked: consentChoices" /> Analytics</span>
							<a className="vm--align" href="#CookiePolicyBanner" onClick={ () => setIsModal(true) }><u>Cookie Policy</u></a>
						</div>
						<div className="cookie__buttons">
							<button className="btn btn__light" onClick={acceptSelected}>Accept Selected</button>
							<button className="btn btn__inverted" onClick={acceptAll}>Accept All</button>
						</div>
					</div>
				</div>
			}

			{!isModal && hasConsent &&
				<div className="cookie__corner">
					<a className="vm--align" href="#CookiePolicyCorner" onClick={ () => setIsModal(true) }><u>Cookie Policy</u></a>
				</div>
			}

			{isModal &&
				<div className="modal__overlay active cookie__modal">
					<div className="modal active animate__animated animate__fadeIn" style={{width: '100%', maxWidth: '960px'}}>
						<div className="modal__header">
							<div className="modal__header-title pleft--10 pright--10">
								<h2>Cookie Policy - SmartyQ</h2>
								<div className="text--muted font--14">Last updated December 07, 2023</div>
							</div>
							<div className="close-modal modal--x" onClick={() => { setIsModal(false) }}>
								<svg viewBox="0 0 20 20">
									<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
								</svg>
							</div>
						</div>
						<div className="modal-content" style={{ paddingTop: '50px' }}>
							<div className="modal__body">
									<div className="mtop--20">
										<b>SmartyQ is committed to ensuring that your privacy is protected</b>. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will exclusively be used in accordance with the privacy laws, will be stored in encrypted form just for the duration needed, and never be shared with or sold to third-parties.
									</div>
									<br/>
									<h3>What are cookies?</h3>
									<p>
										Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
									</p>
									<p>
										Cookies set by the website owner (in this case, SmartyQ) are called <b>"first-party"</b> cookies. Cookies set by parties other than the website owner are called <b>"third-party"</b> cookies. Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
									</p>
									<br />
									<h3>Why do we use cookies?</h3>
									<p>
										We use first-party and third-party cookies for the following reasons.
										<ul>
											<li> <b>Essential cookies</b>: Some cookies are necessary for the website to operate properly and provide its services and thus cannot be avoided. Examples include user authentication, securing private user resources etc.
											</li>
											<li> <b>Functionality cookies</b>: These cookies store user preferences like the language, region, currency or login information of the user, so that the users do not need to renter them every time they visit the website
											</li>
											<li> <b>Analytics cookies</b>: These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our 	marketing campaigns are, or to help us customize our Website for you.
											</li>
											<li> <b>Marketing Cookies</b>: These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.
											</li>
										</ul>
									</p>
									<br />
									<h3>How can I control cookies?</h3>
									<p>
										You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.

										The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.
									</p>
									<br />
									<h3 nstyle="margin-top: 20px;">Cookie Analysis</h3>
									<p> The specific types of first-party and third-party cookies served through our Website and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific web page you visit): </p>
									<div style={{display: 'flex', justifyContent: 'space-between',}}>
										<button className="btn btn__light" onClick={acceptSelected}>Accept Selected</button>
										<button className="btn btn__inverted" onClick={acceptAll}>Accept All</button>
									</div>
									<br />
									<h4>Essential Cookies: <span><input type="checkbox" checked disabled /></span></h4>
									<p>These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.</p>
									{ ESSENTIAL_COOKIES.map(cookie => ( 
										<Cookie {...cookie} />
									))}
									<br />
									<h4>Analytics Cookies: &nbsp;<span><input type="checkbox" value="a" data-bind="checked: consentChoices" /> </span></h4>
									<p>These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.</p>
									{ ANALYTICS_COOKIES.map(cookie => ( 
										<Cookie {...cookie} />
									))}

									<div style={{display: 'flex', justifyContent: 'space-between', marginTop: 30}}>
										<button className="btn btn__light" onClick={acceptSelected}>Accept Selected</button>
										<button className="btn btn__inverted" onClick={acceptAll}>Accept All</button>
									</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
}

export default CookieBanner;
