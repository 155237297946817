import React, { useEffect } from "react";

import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import { useNavigate } from 'react-router-dom';

import {auth} from "../firebase";

export default function AuthPage() {

	const user = useAuthUser(["user"], auth);
	const navigate = useNavigate()

	function loginWithGoogle() {
		console.log("hi")
		const provider = new GoogleAuthProvider()
		provider.setCustomParameters({
			prompt: 'select_account'
		})
		signInWithPopup(auth, provider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				// const credential = GoogleAuthProvider.credentialFromResult(result);
				// const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				console.log("user", user)
				// IdP data available using getAdditionalUserInfo(result)
				// ...
			}).catch((error) => {
				// Handle Errors here.
				// const errorCode = error.code;
				// const errorMessage = error.message;
				// // The email of the user's account used.
				// const email = error.customData.email;
				// The AuthCredential type that was used.
				// const credential = GoogleAuthProvider.credentialFromError(error);
				// ...
			});

	}

	useEffect(() => {
		if(user.data){
			navigate('/tests');
		}
	}, [user.data, navigate])

	if (user.isLoading) {
		return (
			<div className="text-center text-xl m-10">
				Logging in...
			</div>
		);
	}

	if(user.data) {
		return null;
	}

	return (
		<section className="text-center grow mt-20">
			<h2 className="text-3xl font-bold m-10"> Register / Login </h2>
			<div className="flex justify-center">
				<button
					aria-label="Continue with google"
					className="focus:outline-none py-3.5 px-8 border rounded-lg border-gray-700 flex items-center"
					onClick={loginWithGoogle}
				>
					<img
						src="https://api.iconify.design/logos:google-icon.svg"
						alt="google"
						width={30}
					/>
					<p className="ml-3 text-xl google-btn-text">Enter with Google</p>
				</button>
			</div>
			<div className="text-center">
				If you don't have an account, we will automatically create one for you. <br /> Just enter with your google credentials.
			</div>

		</section>

	)
}