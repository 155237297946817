const questions = [
  {
    title: "What is SmartyQ?",
    answer: `SmartyQ can generate a quiz, a test, or any kind of question
        paper you want, with the help of AI, wihout any human bias in the 
        judgement of questions. You just import your text and SmartyQ does all the rest
        for you.
         `,
    extra: "You can choose from a variety of forms suchs as:",
  },
  {
    title: "Who is the SmartyQ aimed at? ",
    answer: `SmartyQ is developed for students, teachers, professors, trainers.
        Wether you are a teacher and want to test your students, or you are a student and 
        want to prepare for your exams, it's the quickest and easiest tool to use. SmartyQ's 
        uses are as far as your imagination reaches.`,
    extra: "",
  },
  {
    title: "What solutions does SmartyQ provide?",
    answer: `SmartyQ saves you time and effort as the only thing you have to do
        s to provide a text and it generates your content in no time. It's simple and easy to use,
        with several options, while it makes editing simple and easy.`,
    extra: "",
  },
  {
    title: "What kind of text should i use for input?",
    answer: `SmartyQ can process any kind of text as long as it is on theoretical level,
        e.g History, Geography, Biology etc.
        SmartyQ will not generate arithmetical exercises for subjects like Math, Chemistry or
        Physics. So ust copy and paste your text and let Q do the rest.`,
    extra: "",
  },
  {
    title: "Where do i find the generated content?",
    answer: `The generated content will be sent to you via email. In order to get your results,
        you need to register first and then log in to an account at SmartyQ.`,
    extra: "",
  },
];

export default questions;
