import { useState, useEffect, useMemo } from "react"
import '../assets/scss/demo.scss';
import img from '../assets/img/logo.png';
import logoImage from "../assets/img/logo.png";
import bulletPointAnimation from '../assets/animations/bulletpoints.json';
import Lottie from "react-lottie-player";

function CreateActions({ submitAction, loading, error, wordCount }) {
	const [type, setType] = useState('questions');
	const [selectedOption, setSelectedOption] = useState('multipleChoice');
	const [outputLang, setOutputLang] = useState({ value: 'same', label: 'Same as input' })
	const langOptions = useMemo(() => {
		return [
			{ value: 'same', label: 'Same language', image: 'https://api.iconify.design/ic:baseline-language.svg' },
			{ value: 'en', label: 'English', image: 'https://api.iconify.design/cif:gb.svg' },
			{ value: 'el', label: 'Greek', image: 'https://api.iconify.design/cif:gr.svg' },
			{ value: 'de', label: 'German', image: 'https://api.iconify.design/cif:de.svg' },
			{ value: 'es', label: 'Spanish', image: 'https://api.iconify.design/cif:es.svg' },
			{ value: 'fr', label: 'French', image: 'https://api.iconify.design/cif:fr.svg' },
			{ value: 'it', label: 'Italian', image: 'https://api.iconify.design/cif:it.svg' },
		];
	}, [])
	const [flag, setFlag] = useState('https://api.iconify.design/ic:baseline-language.svg');

	const questionsOptions = [
		{
			title: 'Multiple choice',
			value: 'multipleChoice'
		},
		{
			title: 'Comprehension',
			value: 'advancedQuestions'
		},
		{
			title: 'Fill the blanks',
			value: 'fillTheBlanks'
		},
		{
			title: 'True/False',
			value: 'trueFalse'
		}
	]
	const summaryOptions = [
		{
			title: 'Short',
			value: 'Medium'
		},
	]
	if(wordCount > 250){
		summaryOptions.push({
			title: 'Large',
			value: 'Large'
		})
	}
	useEffect(() => {
		const f = langOptions.find(lang => lang.label === outputLang);
		if (f) {
			setFlag(f.image);
		} else {
			setFlag('https://api.iconify.design/ic:baseline-language.svg');
		}
	}, [outputLang, langOptions])

	return (
		<div className="demo__right sliding__wrapper" disabled={loading}>
			<h4 className="flex flex__row">
				<img src={logoImage} alt="Logo" style={{ filter: 'hue-rotate(213deg)', width: '70px' }} />
				<div className="mtop--15 font__23">Hey, It's Q.</div>
			</h4>
			<h4>What do you want to create?</h4>
			<div className="select__types">
				<div className={`text__normal select__types-type ${type === 'summary' && 'select__types-typeActive'}`} onClick={() => setType('summary')}>
					<span className="material-icons font__25">
						summarize
					</span>
					<div className="font__16">Summary</div>
				</div>
				<div className={`text__normal select__types-type ${type === 'Bullet Points' && 'select__types-typeActive'}`} onClick={() => setType('Bullet Points')}>
					<span className="material-icons font__25">
						checklist
					</span>
					<div className="font__16">Bullet points</div>
				</div>
				<div className={`text__normal select__types-type ${type === 'questions' && 'select__types-typeActive'}`} onClick={() => setType('questions')}>
					<span className="material-icons font__25">
						question_answer
					</span>
					<div className="font__16">Questions</div>
				</div>
			</div>
			{type === 'questions' &&
				<div className="action__container">
					<h4>Select question type</h4>
					<div className="mtop--20 flex flex-col">
						{questionsOptions.map((option, idx) => {
							return (
								<div key={`qoption_${idx}`} className={`btn question__type-selection ${selectedOption === option.value ? 'btn__primary-outline' : 'btn__secondary'}`} onClick={() => setSelectedOption(option.value)}>
									{selectedOption === option.value &&
										<span className='material-icons text__primary'>
											radio_button_checked
										</span>
									}
									{selectedOption !== option.value &&
										<span className='material-icons'>
											radio_button_unchecked
										</span>
									}
									<span className="inline-block ml-2">{option.title}</span>
								</div>
							)
						})}
					</div>
				</div>
			}
			{type === 'Bullet Points' && 
				<div className="action__container text__center">
					<Lottie
						loop={loading}
						animationData={bulletPointAnimation}
						play
						style={{ width: 220, height: 220, margin: 'auto' }}
					/>
					<span className="material-icons font--30">arrow_downwards</span>
				</div>
			}
			{type === 'summary' &&
				<div className="action__container">
					<h4>Select summary type</h4>
					<div className="mtop--20 flex flex-col">
						{summaryOptions.map((option, idx) => {
							return (
								<div key={`soption_${idx}`} className={`btn question__type-selection ${selectedOption === option.value ? 'btn__primary-outline' : 'btn__secondary'}`} onClick={() => setSelectedOption(option.value)}>
									{selectedOption === option.value &&
										<span className='material-icons text__primary'>
											radio_button_checked
										</span>
									}
									{selectedOption !== option.value &&
										<span className='material-icons'>
											radio_button_unchecked
										</span>
									}
									<span className="inline-block ml-2">{option.title}</span>
								</div>
							)
						})}
					</div>
				</div>
			}
			{error &&
				<div className="text__danger">
					{error}
				</div>
			}
			<div className="mtop--20 action__generate">
				<div className="btn btn__inverted btn__100 font__20" onClick={() => submitAction(type, outputLang, selectedOption)}>
					{loading && 'Generating...'}
					{!loading && <span>Generate <span span className="capitalize">{type}</span></span>}
				</div>
				{selectedOption !== 'fillTheBlanks' &&
					<>
						<div className="mtop--5">in</div>
						<div className="btn btn__secondary-outline btn__100 btn__md font__20 flex flex__row justify__center">
							<img src={flag} width={30} className="mtop--5 mright--5" alt="lang" />
							<select className="font__14 flex flex__row" value={outputLang} onChange={(e) => setOutputLang(e.target.value)} style={{margin: 'auto'}}>
								{langOptions.map((language, index) => {
									return (
										<option key={`language__option${index}`}>
											<div>
												<img width={30} src={img} alt="flag" />
												{language.label}
											</div>
										</option>
									)
								})}
							</select>
						</div>
					</>
				}
			</div>
		</div>
	)
}

export default CreateActions