// PdfDocument.js
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import Roboto from '../assets/Roboto-Regular.ttf';
Font.register({ family: 'Roboto', src: Roboto });

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: '#fff',
		fontFamily: "Roboto",
		padding: '10px 5px'
	},
	section: {
		margin: 5,
		padding: 10,
		flexGrow: 1,
	},
	answers: {
		margin: 5,
		marginLeft: 20,
		fontSize: 12
	},
	question: {
		fontWeight: 900,
		fontSize: 14
	}
});

const PdfDocument = ({data, uid, testId}) => (
	<Document>
		<Page size="A4" style={styles.page}>
			{data?.map((question, index) => (
				<>
					{console.log(question)}
					{question.type === 'fillTheBlanks' && 
						<View style={styles.section}>
							<Text style={styles.question}>{index + 1}. {question.sentence}</Text>
						</View>
					}
					{question.type === 'multipleChoice' && 
						<View style={styles.section}>
							<Text style={styles.question}>{index + 1}. {question.question}</Text>
							{question?.answers?.map((answer, idx) => {
								return (
									<Text style={styles.answers}>&#183; {answer}</Text>
								)
							})}
						</View>
					}
					{(question.type !== 'multipleChoice' && question.type !== 'fillTheBlanks') &&
						<View style={styles.section}>
							<Text style={styles.question}>{index + 1}. {question.question}</Text>
						</View>
					}
				</>
			))}
		</Page>
	</Document>
);
export default PdfDocument;
