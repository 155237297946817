import { useRef, useState } from "react";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf.min.mjs'; //node_modules\pdfjs-dist\legacy\build\pdf.min.mjs
import { WorkerMessageHandler } from 'pdfjs-dist/build/pdf.worker.min.mjs';
import pdfIcon from "../assets/img/pdf.png";
// node_modules\pdfjs-dist\build\pdf.worker.min.mjs
const fileToDataUri = (file) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.onload = (event) => {
		resolve(event.target.result)
	};
	reader.readAsDataURL(file);
})

function UploadDocument({ onLoad }) {
	const inputRef = useRef()
	const [loadError, setLoadError] = useState("")

	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const dataUri = await fileToDataUri(file);
			inputRef.current.value = "";

			try {
				GlobalWorkerOptions.workerSrc = WorkerMessageHandler; // this is not really needed, but importing it is needed
				const pdfDoc = await getDocument(dataUri).promise;
				let pdfPages = [];

				for (let i = 1; i <= pdfDoc.numPages; i++) {
					let page = await pdfDoc.getPage(i);
					let textContent = await page.getTextContent();
					pdfPages.push(textContent.items.map(item => item.str).join(' '))
				}
				onLoad(pdfPages.join('\n\n'));
			} catch (error) {
				setLoadError("Invalid file type")
				setTimeout(() => setLoadError(''), 2000)
			}

		}
	}
	return (
		<>
			<div className="text-left">
				<label for="pdfFileInput" className="btn btn__secondary btn__100 btn__sm font__14">
					<img src={pdfIcon} width={32} alt="youtube icon" style={{display:"inline-block"}} />&nbsp;
					Upload file (.pdf)
				</label><br />
				<input type="file" ref={inputRef} id="pdfFileInput" name="file" title="asd" onChange={handleFileChange} style={{ display: 'none' }} />
				{loadError &&
					<span className="text-red-600"> {loadError} </span>
				}
			</div>
		</>
	)
}

export default UploadDocument