import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCnD3kpBrsHu4G4kbUO8RYezuASeIJgsx8",
	authDomain: "smartyq-b8770.firebaseapp.com",
	projectId: "smartyq-b8770",
	storageBucket: "smartyq-b8770.appspot.com",
	messagingSenderId: "323560450964",
	appId: "1:323560450964:web:5c5452a84076272a4efd81",
	measurementId: "G-KWX5Z9Y19V"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebase);

const getCookie = (name) => {
	var nameEQ = name + "=";
	var cookies = document.cookie.split(';');

	for (var i = 0; i < cookies.length; i++) {
		var cookie = cookies[i];
		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1, cookie.length);
		}
		if (cookie.indexOf(nameEQ) === 0) {
			return cookie.substring(nameEQ.length, cookie.length);
		}
	}

	return null;
}

const setCookie = (name, value, days) => {
	var expires = "";

	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}

	document.cookie = name + "=" + value + expires + "; path=/";
}

export const submitCookieChoices = (choices) => {
	setCookie("SQ_CONSENT", choices.join(","), 365); //expire in a year
}

export const checkConsent = () => {
	const cookieValue = getCookie("SQ_CONSENT"); // ["f", "a", "m"]
	if(cookieValue !== null){
		const consentChoices = cookieValue.split(",");

		if(consentChoices.includes('a')){
			firebase.automaticDataCollectionEnabled = true;
		}
		else{
			firebase.automaticDataCollectionEnabled = false;
		}
		return true; //has given consent
		// if(consentChoices.includes('m')){
		// }
	}
	
	return false; //has not given consent
}

checkConsent();


export const auth = getAuth(firebase);
export const firestore = getFirestore(firebase);