import { useState } from "react";
import {  useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
	query,
	// doc,
	collection,
	where
} from "firebase/firestore";
import { firestore } from "../firebase";
import { Question } from "./Questions";
import PdfDocument from './PdfDocument';
import { PDFViewer } from '@react-pdf/renderer';

export default function TestQuestions({ uid, testId }) {
	const [page, setPage] = useState('generated');
	const questionsCol = collection(firestore, "users", uid, "questions");
	const questionsQuery = query(questionsCol, where("testIds", "array-contains", testId));
	const testQuestions = useFirestoreQueryData(["questions", { testId }], questionsQuery, { idField: 'id', subscribe: true });
	console.log("testQuestions.data", testQuestions.data)
	return (
		<div className="test__content-right text-center">
			<div className="flex flex__row mleft--10">
				<div className="btn btn__secondary-outline">
					{testQuestions?.data?.length} questions selected
				</div>
				<div className="float--right">
					<div className="tabs">
						<div className={`tabs__item tab__50 ${page === 'generated' && 'tabs__item-active'} flex flex__row`} onClick={() => setPage('generated')}>
							<span className="material-icons font__25">
								check
							</span>
							&nbsp;Selected
						</div>
						<div className={`tabs__item tab__50 ${page === 'pdf' && 'tabs__item-active'}`} onClick={() => setPage('pdf')}>
							<span className="material-icons font__25">
								download
							</span>
							&nbsp;Pdf
						</div>
					</div>
				</div>
			</div>
			{testQuestions.isLoading &&
				<div className="text-lg text-center"> Loading questions... </div>
			}
			{testQuestions.isError &&
				<div className="text-lg text-center"> Error loading test </div>
			}
			{page === 'pdf' && 
				<PDFViewer style={{ width: '100%', height: '85vh' }}>
					<PdfDocument data={testQuestions.data} uid={uid} testId={testId} />
				</PDFViewer>
			}
			{page === 'generated' && 
				<div className="mt-4 space-y-4 mx-4 font-sans">
					{testQuestions?.data?.map((question, index) => (
						<Question key={question.id} uid={uid} testId={testId} question={question} number={index} isInTest={true} />
					))}
				</div>
			}			
		</div>
	);

}

