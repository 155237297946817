import logoImage from "../assets/img/logo.png";

export default function Loader({text}) {
	return (
		<div className="flex flex-col items-center">
			<div className="lds-ring">
				<img src={logoImage} alt="Logo" className="__logo" style={{ position: 'absolute', width: '64px', left: '8px', top: '15px', zIndex: 20 }} />
				<div></div>
			</div>
			<p className=""> { text || "Generating..." } </p>
		</div>
	)
}