import { useState } from "react";
import Create from "../components/Create";
import About from "./About";
import Newsletter from "./Newsletter";
import Contact from "./Contact";
// import Stats from "../components/Stats";
import Feedback from "./Feedback";

function FeatureModal({ isOpen, onClose, title, text, description }) {
	return (
		<>
			{ isOpen &&
				<div className="modal__overlay active">
					<div className="modal active animate__animated animate__fadeIn">
						<div className="modal__header">
							<div className="modal__header-title">
								<h4>{title.toUpperCase()}</h4>
							</div>
							<div className="close-modal modal__x">
								<svg viewBox="0 0 20 20" onClick={onClose}>
									<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
								</svg>
							</div>
						</div>
						<div className="modal__content">
							<div className="modal__body">
								<div className="p-5 pt-0">
									<p className="text-xl font-bold text-center">{text}</p>
									<p className="text-xl mt-2">{description}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

function Features() {
	const [isFeatureOpen, setIsFeatureOpen] = useState(false)
	const [selectedFeature, setSelectedFeature] = useState(0)

	const features = [
		{
			title: "Fill in the Blanks",
			text: `Completed sentences with blank spaces to get filled for missing words`,
			description: `Enter the text of your choice and let the AI model recreate it with as many blanks 
			you want. SmartyQ also returns the correct answers for your convenience. However, if you want to up the 
			difficulty of the test, you don't have to use them`,
		},
		{
			title: "Comprehension",
			text: `Creating comprehension questions with their indicative answers based on your text`,
			description: `Generate 5 questions from any given text. The AI model not only creates those
			questions but also answers them for you! You can use them as indicative answers or you can choose
			to ignore them.`,
		},
		{
			title: "True or False",
			text: `Edited sentences from your text which you will mark as True or False`,
			description: `Create True or False sentences for the text of your choice. The AI model creates
			False sentences wth a variety of methods. There can be False sentences with just one word altered
			or there can be completely altered sentences. Pick your favorites and create the 
			questionnaire you want.`,

		},
		{
			title: "Multiple Choice",
			text: `Generate questions with a set of possible answers of which one is correct`,
			description: `Create multiple choice sentences. Just enter the text of your choice and get
			a variety of multiple choice sentences with the right and wrong choices. Pick your favorites and
			create the quistionnaire you want.`,
		},
	];

	const showFeature = (index) => {
		setSelectedFeature(index)
		setIsFeatureOpen(true)
	}

	return (
		<section id="features" className="flex flex-col justify-center items-center pb-20 px-10">
			<h2 className="main__text font__righteous my-10">Features</h2>
			<div className="flex space-x-8 text-center features">
				{ features && features.map( (feature, index) => (
					<div key={index} onClick={ () => showFeature(index) } className="card flex-1 card__inverted card__rounded">
						<h3 className="text-xl font-semibold mb-5"> {feature.title.toUpperCase()} </h3>
						<p className="text-lg font-normal my-5">
							{feature.text}
						</p>
						<span className="btn btn__secondary btn__sm"> Learn More </span>
					</div>
				))}
			</div>
			<FeatureModal isOpen={isFeatureOpen} onClose={ () => setIsFeatureOpen(false) } title={features[selectedFeature].title} text={features[selectedFeature].text} description={features[selectedFeature].description} />
		</section>
	)
}

export default function HomePage() {
	return (
		<div style={{width: '100%'}}>
			<div id="home">
				<Create/>
			</div>
			<div id="newsletter">
				<Newsletter />
			</div>
			<div id="why">
				<About />
			</div>
			<div id="features">
				<Features />
			</div>
			<Feedback />
			{/* <div id="feedback">
				
			</div> */}
			{/* <div id="pricing" className="mtop--30 mbottom--30">
				<div className='text__center'>
					<h2 className="main__text font__righteous">Pricing</h2>
				</div>
				<div className="text__center">
					<h3>Coming Soon.</h3>
				</div>
			</div> */}
			{/* <div id="stats">
				<Stats />
			</div> */}
			<div id="contact">
				<Contact />
			</div>
		</div>
	)
}
