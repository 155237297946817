import { useEffect } from "react";
import UploadDocument from "./UploadDocument";
import YoutubeTranscript from "./YoutubeTranscript";
// import { encode } from 'gpt-tokenizer';

function CreateInput({value, setValue, wordCount, onWordCountChange}) {

	useEffect(() => {
		const w = Math.floor(value.match(/\S+/g)?.length || 0);
		onWordCountChange(w)
	}, [value, onWordCountChange])
	return (
		<>
			<div className="input__wrap demo__input rounded-xl relative">
				<textarea placeholder="Enter your input text" className="bg-white" value={value} onChange={(e) => setValue(e.target.value)}></textarea>
				<span className="focus"></span>
				{value &&
					<span className="absolute bottom-0 right-1" style={{margin: '0px', padding: '3px'}} onClick={() => setValue('')}>
						<span className="material-icons text__danger" style={{ fontSize: '32px'}}>clear</span>
					</span>
				}
			</div>
			<div className="text-lg text-right mb-3 w-11/12">
				<div className="flex px-3 py-2 align-center items-center" style={{justifyContent: 'space-between'}}>
					<UploadDocument onLoad={ (pdfText) => setValue(pdfText) }/>
					<div> Word Count: {wordCount} </div>
					{/* <span> Token Count: {encode(value).length} </span> */}
				</div>
				<div className="flex px-3 py-2 align-center items-center" style={{justifyContent: 'space-between'}}>
					<YoutubeTranscript onLoad={ (transcription) => setValue(transcription) } />
				</div>
			</div>
			
		</>
	)
}

export default CreateInput