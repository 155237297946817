import { useState } from 'react';
import contactAnimation from '../assets/animations/contact';
import {
	collection
} from "firebase/firestore";
import { useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { firestore } from "../firebase";

import Lottie from 'react-lottie-player';
import '../assets/scss/contact.scss';

function Newsletter() {
	const [email, setEmail] = useState('');
	const [isSuccess, setIsSuccess] = useState(false)
	const newsletterCol = collection(firestore, "newsletters");
	const addNewsletter = useFirestoreCollectionMutation(newsletterCol);
	const storeEmail = (e) => {
		e.preventDefault();
		console.log(email)
		addNewsletter.mutate({ email: email, timeCreated: new Date().getTime() })
		setIsSuccess(true)
		setTimeout(() => {setIsSuccess(false); setEmail("")}, 3000)
	}
	return (
		<div className="contact">
			<div className="contact__inverted sm:pb-0 pb-10">
				<div className="text__center flex flex-wrap justify-center items-center ">
					<Lottie
						loop
						animationData={contactAnimation}
						play
						style={{ width: '200px', height: '200px', margin: 'auto' }}
					/>
					<div>
						<h4>Join our newsletter</h4>
						<div>Be the first to know about our latest updates.</div>
						<form className="flex flex__row mtop--20 relative left-6" onSubmit={storeEmail}>
							<div className="input__wrap contact__email">
								<input type="email" placeholder="Enter your email here..." className='bg__white' value={email} onChange={(e) => setEmail(e.target.value)} disabled={isSuccess}/>
								<span className="focus"></span>
							</div>
							<div className="btn btn__inverted btn__sm" onClick={storeEmail} style={{ marginTop: '5px' }} disabled={!email}>
								{!isSuccess &&
									<span className="material-icons">
										send
									</span>
								}
								{isSuccess &&
									<span className="material-icons text-green-500">
										done
									</span>
								}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Newsletter 