import birdImage from '../assets/img/undraw_quick_chat_re_bit5.svg';

import '../assets/scss/contact.scss';

function Contact() {
	return (
		<div className="contact">
			<div className="mtop--20">
				<div className='text__center'>
					<h2 className="main__text font__righteous">We are here for you.</h2>
				</div>
				<div className="contact__inner">
					<img src={birdImage} width={300} style={{ margin: 'auto' }} alt="birdImage" />
					<div className="center font__20 m-10">
						<h2>Contact us at <a href="maito: info@smartyq.eu">info@smartyq.eu</a></h2>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contact 