import React, { useState } from "react";
import {  useFirestoreQueryData, useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { useAuthIdToken } from "@react-query-firebase/auth";
import '../assets/scss/dashboard.scss';
import {
	query,
	// doc,
	collection,
	// limit,
	// QuerySnapshot,
	// DocumentData,
} from "firebase/firestore";

import { auth, firestore } from "../firebase";

// import Accordion from "../components/Accordion";
import SourceTexts from "../components/SourceTexts";
// import { first } from "lodash";
import Questions from "../components/Questions";
import TestQuestions from "../components/TestQuestions";

// const CategoryTitle = (props) => {
// 	return (
// 		<span>
// 			{props.title}&nbsp;
// 			{/* <span className="text-gray-300"> Questions </span> */}
// 			(<span className="text-orange-300 font-bold"> {props.count} </span>)
// 		</span>
// 	);
// }

function Test({ uid }) {
	const testsRef = collection(firestore, "users", uid, "tests");
	const testsQuery = query(testsRef);
	const tests = useFirestoreQueryData(["tests"], testsQuery, { idField: 'id', subscribe: true });

	const addTest = useFirestoreCollectionMutation(testsRef);

	const [selectedSource, setSelectedSource] = useState(null)

	if( tests.isLoading ) return (
		<div className="flex-1 text-xl">
			Loading tests...
		</div>
	)
	
	const firstTest = tests.data.length > 0? tests.data[0]: null
	// console.log("tests.data", tests.data)
	
	if(!firstTest){
		return (
			<div className="text-center">
				<h2 className="text-2xl font-bold text-center m-4 mt-0">CREATE A NEW TEST TO PROCEED</h2>
				<div>
					<button 
						className="btn btn-orange inline-block"
						disabled={addTest.isLoading}
						onClick={() => addTest.mutate({name: "My First Test"})}
					>CREATE NEW TEST</button>
				</div>
			</div>
		)
	}
	return (
		<div className="dashboard">
			<div className="dashboard__content">
				<div className="dashboard__menu">
					<SourceTexts isMobile={false} uid={uid} onSelect={(source) => { setSelectedSource(source) }} />
				</div>
				<div className="dashboard__center">
					<Questions key={selectedSource?.id} uid={uid} source={selectedSource} onSelect={() => { }} testId={firstTest.id} />
				</div>
				<div className="dashboard__right">
					<TestQuestions uid={uid} testId={firstTest.id} />
				</div>
			</div>
		</div>
	)
}


export default function TestsPage() {
	// const testsRef = doc(firestore, "users", );
	const tokenResult = useAuthIdToken(["token"], auth);
	const uid = tokenResult.data?.token?.claims?.user_id
	// console.log("user_id", user_id)
	// const testsRef = doc(firestore, "users", user_id || "1", "tests");


	// const tests = useFirestoreDocumentData(["tests"], testsRef, {}, { enabled: !!user_id });

	return (
		<section className="flex grow items-stretch">
			{uid &&
				<Test uid={uid} />
			}
			{!uid &&
				<div className="text-xl text-center grow"> Loading Tests...  </div>
			}
		</section>
	)
}