import React, {useState, useEffect} from 'react'
import GeneralQuestion from "./GeneralQuestion";
import { CopyToClipboard } from 'react-copy-to-clipboard';

function Results({results, setResults, onBack, onClear}) {
	const [copied, setCopied] = useState(false);
	if(!results) return '';

	function WordCount({text}) {
		const [wordCount, setWordCount] = useState(0);
	
		useEffect(() => {
			if(!text) return;
			
			const w = Math.floor(text.match(/\S+/g).length);
			setWordCount(w)
		}, [text])
	
		return (
			<>
				<div className="text-lg text-right mt-2 px-2 w-full">
					<span className="space-x-8">
						<span> Word Count: {wordCount} </span>
						{/* <span> Token Count: {encode(value).length} </span> */}
					</span>
				</div>
			</>
		)
	}
	const ResultActions = () => {
		const [copyText, setCopyText] = useState("");
		
		useEffect(() => {
			let nextCopyText = ""
			if(results?.action === "summaryAction"){
				nextCopyText = results?.results
			}
			else if(results?.action === "multipleChoiceAction"){
				nextCopyText = results?.results.map(q => q.question + '\n' + q.answers.join('\n')).join('\n\n')
			}
			else if(results?.action === "advancedQuestionsAction"){
				nextCopyText = results?.results.map(q => q.question + '\n' + q.answer).join('\n\n')
			}
			else if(results?.action === "trueFalseAction"){
				nextCopyText = results?.results.map(q => q.question + '\n' + q.answer).join('\n\n')
			}
			else if(results?.action === "trueFalseAction"){
				nextCopyText = results?.results.map(q => q.sentence + '\n' + q.blank).join('\n\n')
			}
			setCopyText(nextCopyText)
		}, [])
		
		
		
		return (
			<div className="results__actions relative">
				<button className="btn btn__inverted btn__md" onClick={() => {setResults(null); onBack();}}>
					<span className="material-icons">
						arrow_back
					</span>
					Back
				</button>
				<div className="float--right">
					<button className="btn btn__primary btn__md" onClick={() => {setResults(null); if(onClear) onClear(); }}>
						<span className="material-icons">
							restart_alt
						</span>
						Start new
					</button>
					<CopyToClipboard text={copyText} onCopy={() => {
						setCopied(true); setTimeout(() => {
							setCopied(false);
						}, 2000);
					}}>
						<button className="btn btn__primary btn__md">
							<span className="material-icons">
								{copied ? 'verified' : 'content_copy'}
							</span>
							Copy
						</button>
					</CopyToClipboard>
				</div>
			</div>
		)
	}
	return (
		<>
			<div className="card demo__left-inner animate__animated animate__fadeInLeft" style={{justifyContent: 'flex-start', textAlign: 'left', alignItems: 'flex-start', paddingLeft: '45px'}}>
				<h3 className="main__text">Here is your result!</h3>
				{results?.action === "summaryAction" &&
					<>
						<pre className="card whitespace-pre-wrap rounded demo__result">
							{results.results}
						</pre>
					</>
				}
				{results?.action !== "summaryAction" &&
					<ul className="list-decimal text-lg space-y-4 demo__result">
						{results?.results.map((data, index) => (
							<li className="card card__rounded" style={{boxShadow: 'none', width: '100%'}}>
								<GeneralQuestion key={index} action={results.action} question={data} index={index + 1} />
							</li>
						))}
					</ul>
				}
				{results?.error !== 'No error' &&
					<div className="text__center">
						<h3>An error occured. Please try again later, or with another source.</h3>
					</div>
				}
				{results?.action === "summaryAction" &&
					<WordCount text={results?.results} />
				}
				{results?.action === "multipleChoiceAction" &&
					<WordCount text={results?.results.map(q => q.question + ' ' + q.answers.join(' ')).join(' ') } />
				}
				{results?.action === "advancedQuestionsAction" &&
					<WordCount text={results?.results.map(q => q.question + ' ' + q.answer).join(' ') } />
				}
				{results?.action === "trueFalseAction" &&
					<WordCount text={results?.results.map(q => q.question).join(' ') } />
				}
				{results?.action === "fillTheBlanksAction" &&
					<WordCount text={results?.results.map(q => q.sentence).join(' ') } />
				}
				
			</div>
			<ResultActions />
		</>
	)
}

export default Results