
export default function QMultipleChoice({ data, index }) {
	const question = data.question
	const answers = data.answers
	const correctIndex = data.correct

	console.log("mc data", data)

	return (
		<div>
			<div className="font-semibold"> 
				<span className="text-gray-400 font-normal">{index}. </span> {question} 
			</div>
			<div className="space-y-2 mt-2">
				{answers.map((answer, index) => (
					<div className="pl-5">
						{ (index + 1) + "." } &nbsp;
						{answer}
					</div>
				))}
			</div>
			<div>
				<span className="text-gray-400">Correct Answer: </span> { (correctIndex + 1) }
			</div>
		</div>
	)
}