function Feedback() {
	return (
		<a href="https://conferience.com/app/SmartyQ1en" className="btn btn__inverted btn__feedback font__20">
			<span  className="btn__feedback-text animate__animated animate__bounceIn">
				Your feedback is higly valued. Click to help us become better...
			</span>
			<span className='material-icons'>
				add_comment
			</span>
		</a>
	)
}

export default Feedback
