import React from "react";
import QuestionList from "./QuestionList";

export default function FaqPage() {
  return (
    <>
      <section className="p-10 text-xl terms__container">
        <div className="text-center">
          <h2 className="text-2xl font-bold">Frequently Asked Questions</h2>
        </div>
        <QuestionList />
      </section>
    </>
  );
}
