import QMultipleChoice from "./QMultipleChoice"
import QTrueOrFalse from "./QTrueOrFalse"
import QAdvancedQuestions from "./QAdvancedQuestions"
import QFillTheBlanks from "./QFillTheBlanks"

export default function GeneralQuestion(props) {

	console.log("GeneralQuestion props", props)

	const COMP_MAP = {
		"multipleChoiceAction": QMultipleChoice,
		"advancedQuestionsAction": QAdvancedQuestions,
		"fillTheBlanksAction": QFillTheBlanks,
		"trueFalseAction": QTrueOrFalse
	}

	const GeneralComp = COMP_MAP[props.action]

	return (
		<div className="grow">
			<GeneralComp data={props.question} index={props.index} />
		</div>
	)
}