import leftImage from '../assets/img/1690810181644.png';
import rightImage from '../assets/img/1690810184956.png';
import '../assets/scss/about.scss';
import 'swiper/css';
import 'swiper/css/navigation'; // Import the specific module CSS you need
import 'swiper/css/pagination'; // Import the specific module CSS you need
import 'swiper/css/scrollbar'; // Import the specific module CSS you need
import 'swiper/css/autoplay'; // Import the specific module CSS you need

function About() {
	const videos = [
		{
			src: 'https://www.youtube-nocookie.com/embed/TWR1a6YQgbE?si=vEVch1zZy1FSBnC2',
            title: 'SmartyQ Complete Toolkit',
		},
		{
			src: 'https://www.youtube-nocookie.com/embed/gzz0gY0mVrY?si=pd7_LHbrFbabXVl1',
            title: 'What can teachers do?',
		},
		{
			src: 'https://www.youtube-nocookie.com/embed/6qHF8OIngOY?si=nh8VZQ9-_AQwgCaP',
            title: 'What can students do?',
		},
		{
			src: 'https://www.youtube-nocookie.com/embed/c9IJYbXKFCM?si=ASZJKD8J6LdRMyA5',
            title: ' True/False Tests for Teachers ',
		},
		{
			src: 'https://www.youtube-nocookie.com/embed/7KxdvRScEOw?si=LcNyi8MnZALxxljA',
            title: ' True/False Tests for Students ',
		},
		{
			src: 'https://www.youtube-nocookie.com/embed/E8REHu7eApA?si=vJVi0qyhcVSF6ZEl',
            title: ' Comprehension Tests ',
		},
	]
	return (
		<div className="about">
			<div className='text__center'>
				<h2 className="main__text font__righteous">What is SmartyQ?</h2>
			</div>
			<div className="mtop--20 video__slide-container">
				{videos.map((video, index) => {
					return (
						<div className="video__slide" key={`slide__${index}`}>
							<div className="video__slide-title">{video.title}</div>
							<div className="video__slide-inner">
								<iframe width="340" height="200" src={video.src} title={video.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
							</div>
						</div>
					)
				})}
			</div>
			<div className="about__inner">
				<div className="left">
					<img src={leftImage} width={200} alt="leftPattern"/>
				</div>
				<div className="center font__20">
					Produce <b>comprehension</b>, <b>multiple choice</b>, <b>fill-in-the-blank</b>, <b>true/false</b>, <b>main points</b> and summaries questions in English, Greek, German, French, Italian and Spanish in seconds with a single click.
					<br />
					And it’s <b>free</b>
					<div className="mt-8">
						<a href="#contact" className="btn btn__inverted font__20">Contact us now</a>
					</div>
				</div>
				<div className="right">
					<img src={rightImage} width={200} alt="rightPattern" />
				</div>
			</div>
		</div>
	)
}

export default About