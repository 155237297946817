import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Collapse from "@kunukn/react-collapse";

export default function Accordion(props) {
	const [currentOpen, setCurrentOpen] = useState(0);
	const [collapseStates, setCollapseStates] = useState({});

	function setCollapseState(item, state){
		setCollapseStates( prevStates => {
			return {...prevStates, [item]: state}
		})
	}

	function toggleOpen(index){
		setCurrentOpen( prevOpen => {
			// return prevOpen == index? null: index;
			return index;
		})
	}

	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			{props.children.map((child, index) => (
				<div key={index} className={classNames("item m-2 border-2 border-t-0 border-teal-700", { "item--active": currentOpen === index })}>
					<button className="p-2 pl-4 w-full text-left shadow bg-teal-700 text-white flex justify-between items-center" onClick={() => toggleOpen(index)}>
						<span className="inline-block flex-none">
							<span>{child.props.title}</span> <span>{collapseStates[index]}</span>
						</span>
					</button>
					<Collapse
						className="collapse-height-transition not-bg-slate-100"
						isOpen={currentOpen === index}
						onChange={({ state }) => {
							setCollapseState({[index]: state});
						}}
						onInit={({ state }) => {
							setCollapseState({[index]: state});
						}}
					>
						{child}
						<button className="btn inline-block my-4"> Generate </button>
					</Collapse>
				</div>
			))}
		</>
		
	);
}