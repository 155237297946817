import React from "react";

export default function PrivacyPage() {
  return (
    <div class="privacy text-xl p-10 terms__container">
      <div class="text-center text-3xl font-bold mb-10">Privacy Policy for SmartyQ</div>
      <div className="">
        <p className="terms__content">
          At SmartyQ, accessible from smartyq.eu, one of our main priorities is
          the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by SmartyQ and how
          we use it.
        </p>
        
        <p className="terms__content">
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to <a href="/contact">contact us.</a>{" "}
        </p>
        

        <p className="terms__content">
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in SmartyQ. This policy is not applicable to any
          information collected offline or via channels other than this website.
        </p>

        <h3 className="text-xl font-semibold">Consent</h3>
        <p className="">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>

        <h3 className="text-xl font-semibold">
          Information we collect{" "}
        </h3>
        <p className="terms__content">
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p className="terms__content">
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <p className="terms__content">
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number. We never sell your information to
          3rd parties.
        </p>

        <h3 className="text-xl font-semibold">
          How we use your information
        </h3>
        <p className="terms__content">
          We use the information we collect in various ways, including to:
        </p>
        
        <ul className="terms__list">
          <li>Provide, operate, and maintain our product </li>
          <li>Improve, personalize, and expand our product </li>

          <li>Understand and analyze how you use our product </li>
          <li>Develop new products, services, features, and functionality </li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes{" "}
          </li>
          <li>Send you emails </li>
        </ul>
        

        <h3 className="text-xl font-semibold">
          Log Files
        </h3>
        <p className="terms__content">
          SmartyQ follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services’ analytics.
        </p>
        
        <p className="terms__content">
          The information collected by log files includes internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and
          time stamp, referring/exit pages, and possibly the number of clicks.
          These are not linked to any information that is personally
          identifiable.
        </p>
        
        <p className="terms__content">
          The purpose of the information is for analyzing trends, administering
          the site, tracking users’ movement on the website, and gathering
          demographic information.
        </p>
        

        <h3 className="text-xl font-semibold">
          Cookies and Web Beacons
        </h3>
        <p className="terms__content">
          Like any other website, SmartyQ uses ‘cookies’. These cookies are used
          to store information including visitors’ preferences, and the pages on
          the website that the visitor accessed or visited.
        </p>
        
        <p className="terms__content">
          The information is used to optimize the users’ experience by
          customizing our web page content based on visitors’ browser type
          and/or other information.
        </p>

        <h3 className="text-xl font-semibold">
          Google DoubleClick DART Cookie
        </h3>
        <p className="terms__content">
          Google is one of the third-party vendors on our site. It also uses
          cookies, known as DART cookies, to serve ads to our site visitors
          based upon their visit to www.website.com and other sites on the
          internet. However, visitors may choose to decline the use of DART
          cookies by visiting the Google ad and content network Privacy Policy
          at the following URL –{" "}
          <a href="https://policies.google.com/technologies/ads">
            https://policies.google.com/technologies/ads
          </a>
        </p>

        <h3 className="text-xl font-semibold">
          Our Advertising Partners
        </h3>
        <p className="terms__content">
          Some of the advertisers on our site may use cookies and web beacons.
          Our advertising partners are listed below. Each of our advertising
          partners has its own Privacy Policy for its policies on user data. For
          easier access, we hyperlinked to their Privacy Policies below.
        </p>
        
        <p className="terms_content">
          <span>
            Google: &nbsp;
          </span>
          <a href="https://policies.google.com/technologies/ads">
            https://policies.google.com/technologies/ads
          </a>
        </p>

        <h3 className="text-xl font-semibold">
          Advertising Partners Privacy Policies
        </h3>
        <p className="terms__content">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of SmartyQ.
        </p>
        
        <p className="terms__content">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on SmartyQ, which are sent
          directly to users’ browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>
        
        <p className="terms__content">
          Note that SmartyQ has no access to or control over these cookies that
          are used by third-party advertisers.
        </p>

        <h3 className="text-xl font-semibold">
          Third Party Privacy Policies
        </h3>
        <p className="terms__content">
          SmartyQ’s Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt out of certain options.
        </p>
        
        <p className="terms__content">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers’
          respective websites.
        </p>

        <h3 className="text-xl font-semibold">
          GDPR Data Protection Rights
        </h3>
        <p className="terms__content">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        
        <p className="terms__content">
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        
        <p className="terms__content">
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        
        <p className="terms__content">
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        
        <p className="terms__content">
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        
        <p className="terms__content">
          TThe right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        
        <p className="terms__content">
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p className="terms__content">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        

        <h3 className="text-xl font-semibold">
          Children’s Information
        </h3>
        <p className="terms__content">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
          
          SmartyQ does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </div>
    </div>
  );
}
