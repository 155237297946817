

export default function QFillTheBlanks({ data, index }) {
	const question = data.sentence
	const answer = data.blank
	
	return (
		<div>
			<div className="font-semibold"> 
				<span className="text-gray-400 font-normal">{index}. </span> {question} 
			</div>
			<div>
				<span className="text-gray-400"> Answer: </span> { Array.isArray(answer)? answer.join(","): answer }
			</div>
		</div>
	)
}